import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { MdPersonPin } from "react-icons/md";
import { FaHouseChimneyUser } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png'
import { TbLogout2 } from "react-icons/tb";
import { IoAnalyticsOutline } from "react-icons/io5";
import authService from '../services/authService';
import { useNavigate } from 'react-router-dom';

import { jwtDecode } from 'jwt-decode';
import { isGhostUser } from '../services/ghostCheck';
import { Sparkles } from 'lucide-react';



const SideBar = () => {
    const navigate = useNavigate();
    // const user = localStorage.getItem("userToken")
    const [isGhost, setIsGhost] = useState(false);


    // const decodedToken = jwtDecode(user.userToken);


    // const user = JSON.parse(localStorage.getItem('user'));

    // if (user && user.userToken) {
    //     try {
    //         decodedToken = jwtDecode(user.userToken);
    //     } catch (error) {
    //         console.error("Error decoding the token:", error);
    //     }
    // } else {
    //     console.warn("No user or user token found in local storage.");
    // }

    useEffect(() => {
        setIsGhost(isGhostUser());
    }, []);

    const handleLogout = async () => {
        try {
            await authService.logout();
            navigate('/');
        } catch (error) {
            console.error("error logging out user", error);
        }
    }

    if(isGhost) return (
        <aside className="w-64 flex flex-col h-screen pr-3 text-gray-300 bg-[#161616] justify-between" aria-label="Sidebar">
            <div className="overflow-y-auto py-4 px-3 ">
                <a href="/assistants" className="flex items-center space-x-2 px-4 h-16 mt-3 mb-5">
                    <span className="flex items-center text-2xl font-bold text-gray-200 space-x-2">
                        <img src={logo} alt="GuestLink Logo" className='h-10 w-10 -ml-4' />
                        <p className='mb-2 -ml-4 text-center mt-1'>GuestLink</p>
                    </span>
                </a>
                <nav className="space-y-3">
                    <NavLink to="/create-assistant" className={({ isActive }) =>
                        `flex transition duration-100 items-center px-4 py-2 mt-2 text-sm font-semibold text-white bg-transparent rounded-lg hover:bg-gray-700 focus:bg-gray-700 focus:outline-none focus:shadow-outline ${isActive ? "nav-link-active" : ""} nav-link`}>
                        <MdPersonPin className='mr-2' size={24} />
                        Create Assistant
                    </NavLink>
                </nav>
            </div>
        </aside>
    )

    return (
        <aside className="w-64 flex flex-col h-screen pr-3 text-gray-300 bg-[#161616] justify-between" aria-label="Sidebar">
            <div className="overflow-y-auto py-4 px-3 ">
                <a href="/assistants" className="flex items-center space-x-2 px-4 h-16 mt-3 mb-5">
                    <span className="flex items-center text-2xl font-bold text-gray-200 space-x-2">
                        <img src={logo} alt="GuestLink Logo" className='h-10 w-10 -ml-4' />
                        <p className='mb-2 -ml-4 text-center mt-1'>GuestLink</p>
                    </span>
                </a>
                
                <nav className="space-y-3">
                    <NavLink to="/create-assistant" className={({ isActive }) =>
                        `flex transition duration-100 items-center px-4 py-2 mt-2 text-sm font-semibold text-white bg-transparent rounded-lg hover:bg-gray-700 focus:bg-gray-700 focus:outline-none focus:shadow-outline ${isActive ? "nav-link-active" : ""} nav-link`}>
                        <MdPersonPin className='mr-2' size={24} />
                        Create Assistant
                    </NavLink>
                    <NavLink to="/assistants" className={({ isActive }) =>
                        `flex transition duration-100 items-center px-4 py-2 mt-2 text-sm font-semibold text-white bg-transparent rounded-lg hover:bg-gray-700 focus:bg-gray-700 focus:outline-none focus:shadow-outline ${isActive ? "nav-link-active" : ""} nav-link`}>
                        <FaHouseChimneyUser className='mr-3' size={20} />
                        Assistants
                    </NavLink>
                    <NavLink to="/analytics" className={({ isActive }) =>
                        `flex transition duration-100 items-center px-4 py-2 mt-2 text-sm font-semibold text-white bg-transparent rounded-lg hover:bg-gray-700 focus:bg-gray-700 focus:outline-none focus:shadow-outline ${isActive ? "nav-link-active" : ""} nav-link`}>
                        <IoAnalyticsOutline className='mr-4' size={18} />
                        Stats
                    </NavLink>
                    <NavLink to="/communication" className={({ isActive }) =>
                        `flex transition duration-100 items-center px-4 py-2 mt-2 text-sm font-semibold text-white bg-transparent rounded-lg hover:bg-gray-700 focus:bg-gray-700 focus:outline-none focus:shadow-outline ${isActive ? "nav-link-active" : ""} nav-link`}>
                        <Sparkles className='mr-4' size={18} />
                        Outreach
                    </NavLink>
                    
                </nav>
            </div>
            <div className='py-4 px-3'>
                <div className='bg-gray-700 short-height w-full rounded-full mb-4'></div>
                <Link onClick={handleLogout} className="flex transition duration-100 items-center px-4 py-2 mt-2 text-sm font-semibold text-white bg-transparent rounded-lg hover:bg-gray-700 focus:bg-gray-700 focus:outline-none focus:shadow-outline">
                    <TbLogout2 className='mr-4' size={18} />
                    Logout
                </Link>
            </div>
        </aside>
    );
}

export default SideBar;